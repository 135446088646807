<script>
export default {
  data() {
    return {
      selectedOption: '',
      isButtonDisabled: true,
    };
  },
  computed: {
    buttonClass() {
      return this.selectedOption ? 'button-active' : 'button-disabled';
    },
  },
  methods: {
    updateButton() {
      this.isButtonDisabled = this.selectedOption === '';
    },
    handleButtonClick() {
      if (this.selectedOption) {
        window.open(this.selectedOption, '_blank');
      }
    },
  },
};
</script>

<template>
  <div class="landing">
    <div class="center-content">
      <!-- SECTION - HERO -->
      <main class="sectionhero">
        <nav class="header">
          <div class="frame-3">
            <router-link :to="{'name' : 'Welcome Page'}" class="header-logo"><img src="../assets/icons/WelcomePage/delimaflix-logo-color1.svg" class="header-logo"/></router-link>
          </div>
          <div class="login-container">
            <div class="login-section">
              <select id="dropdown-container" class="btn-login" v-model="selectedOption" @change="updateButton">
                <option value="" selected disabled>Pilih Kategori</option>
                <option value="https://delimaflix.moe.gov.my/dashboard">Pelajar</option>
                <option value="https://app-delima.moe.gov.my/mam/login">BSTP</option>
                <option value="https://app-delima.moe.gov.my/mps/login">Guru</option>
              </select>
              <div class="icon-container">
                <img src="../assets/icons/WelcomePage/icon-arrow-down.svg" class=""/>
              </div>
            </div>
            <div class="login-section">
              <button
                id="loginButton"
                :disabled="isButtonDisabled"
                :class="buttonClass"
                @click="handleButtonClick"
              >
                <div class="text-wrapper-3 desktop-only">Log Masuk</div>
                <img src="../assets/icons/WelcomePage/icon-login.svg" class="login-icon mobile-only"/>
              </button>
            </div>
          </div>
        </nav>

        <div class="frame">
          <div class="frame-2">
            <h1 class="delimaflix"><span class="text-wrapper">DELIMaFLiX</span></h1>
            <h2 class="ajar-belajar-di-mana">Ajar &amp; Belajar Di Mana-Mana Saja!</h2>
            <p class="hero-text">
              DELIMaFLiX adalah satu platform yang menyatukan tempat pengajaran dan pembelajaran. Mudah digunakan
              dan selamat - DELIMaFLiX membantu pendidik dan pelajar mengelola, mengukur, dan memperkaya suasana pembelajaran yang optimum.
            </p>
          </div>
          <div>
            <a class="btn-blue-l" target="_blank" href="https://delimaflix.moe.gov.my/dashboard">
              <div class="div-2"><div class="text-wrapper-2">Mula Belajar</div></div>
            </a>
          </div>
          <div class="frame">
            <img class="desktop-pelajar" src="../assets/Images/Views/WelcomePage/Hero-img.png" />
          </div>
        </div>
      </main>
      <!-- SECTION - HERO - END -->


      <!-- SECTION 1 - FAEDAH MENGGUNAKAN EDUVAULT -->
      <div class="section1">
        <div class="faedah-menggunakan">
          <h2><span class="text-wrapper-7">Faedah Menggunakan DELIMaFLiX</span> </h2>
        </div>

        <div class="frame-4">
          <div class="frame-5">
            <img class="layer" src="../assets/icons/WelcomePage/faedah-1.svg" />
            <div class="group">
              <div class="subtitle">
                <p class="text-wrapper-9">
                  Kumpulkan semua alat pembelajaran anda dan uruskan pelbagai kelas dalam satu pusat terancang.
                </p>
              </div>
              <div class="frame-6">
                <div class="number">
                  <div class="overlap-group"><div class="text-wrapper-10">1</div></div>
                </div>
                <div class="text-wrapper-11">Semua di satu platform</div>
              </div>
            </div>
          </div>
          <div class="frame-5">
            <img class="img" src="../assets/icons/WelcomePage/faedah-2.svg" />
            <div class="group-2">
              <div class="subtitle">
                <p class="text-wrapper-9">
                  Sesiapa dalam komuniti sekolah anda boleh memulai dan menggunakan DELIMaFLiX hanya dalam beberapa minit.
                </p>
              </div>
              <div class="frame-7">
                <div class="number">
                  <div class="overlap-group"><div class="text-wrapper-12">2</div></div>
                </div>
                <div class="text-wrapper-11">Mudah digunakan</div>
              </div>
            </div>
          </div>
          <div class="frame-5">
            <img class="group-3" src="../assets/icons/WelcomePage/faedah-3.svg" />
            <div class="group-2">
              <div class="subtitle">
                <p class="text-wrapper-9">
                  Bekerja pada dokumen yang sama dengan seluruh kelas secara serentak atau berhubung secara langsung
                  melalui fungsi DELIMaFLiX Meetings.
                </p>
              </div>
              <div class="frame-8">
                <div class="number">
                  <div class="overlap-group"><div class="text-wrapper-12">3</div></div>
                </div>
                <div class="text-wrapper-11">Dicipta untuk kerjasama</div>
              </div>
            </div>
          </div>
          <div class="frame-5">
            <img class="group-4" src="../assets/icons/WelcomePage/faedah-4.svg" />
            <div class="group-5">
              <div class="subtitle">
                <p class="text-wrapper-9">
                  Mempertingkatkan pengajaran dan pembelajaran dari mana-mana sahaja, pada peranti apa pun, dan
                  memberikan kelas anda fleksibiliti dan keterjangkauan yang lebih besar.
                </p>
              </div>
              <div class="frame-9">
                <div class="number">
                  <div class="overlap-group"><div class="text-wrapper-13">4</div></div>
                </div>
                <div class="text-wrapper-11">Akses dari mana-mana</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- SECTION 1 - END -->

      <!-- SECTION 2 - TENTANG DELIMA FLIX -->
      <div id="section-tentang-EDUVAULT" class="tentang-EDUVAULT">
        <h2><span class="text-wrapper-7">Tentang DELIMaFLiX</span></h2>
      </div>

      <div class="section2">
        <!-- section 2.1 -->
        <div class="group-19">
          <div class="group-20">
            <div class="overlap-7">
              <img class="rectangle-2" src="../assets/Images/Views/WelcomePage/urus-modal-pelajaran-1.png" />
            </div>
          </div>
          <div class="group-20">
            <div class="frame-20">
              <div class="text-wrapper-29">Urus Modul Pelajaran</div>
              <p class="text-wrapper-30">
                Mengurus dengan mudah menggunakan alat untuk pengawalan, analisis dan kebolehlihatan
              </p>
              <div class="frame-21">
                <div class="details">
                  <div class="done">
                    <div class="path-wrapper">
                      <img src="../assets/icons/WelcomePage/tick-icon.svg" />
                    </div>
                  </div>
                  <p class="text-wrapper-31">
                    Akses log audit DELIMaFLiX secara langsung dari Konsol Pentadbiran untuk mengkaji peristiwa dengan
                    mendalam dan menganalisis prestasi serta isu keselamatan.
                  </p>
                </div>
                <div class="details">
                  <div class="done">
                    <div class="path-wrapper">
                      <img src="../assets/icons/WelcomePage/tick-icon.svg" />
                    </div>
                  </div>
                  <p class="text-wrapper-31">
                    Gunakan laporan untuk menyelidik isu seperti prestasi pelajar atau kelas, dan tingkatkan pengalaman
                    pembelajaran dalam talian dengan cepat dan mudah.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- section 2.2 -->
        <div class="group-34">
          <div class="frame-25">
            <div class="frame-20">
              <div class="text-wrapper-29">Mudah Digunakan</div>
              <p class="text-wrapper-30">Jimatkan masa dan permudahkan tugas harian</p>
              <div class="frame-21">
                <div class="details">
                  <div class="done">
                    <div class="overlap-group-9">
                      <img src="../assets/icons/WelcomePage/tick-icon.svg" />
                    </div>
                  </div>
                  <p class="text-wrapper-31">Tukar antara kelas, tugasan, dan pelajar hanya dengan beberapa klik.</p>
                </div>
                <div class="details">
                  <div class="done">
                    <div class="overlap-group-9">
                      <img src="../assets/icons/WelcomePage/tick-icon.svg" />
                    </div>
                  </div>
                  <p class="text-wrapper-31">Sediakan, jadualkan tugasan dan kuiz yang melibatkan pelbagai kelas.</p>
                </div>
                <div class="details">
                  <div class="done">
                    <div class="overlap-group-9">
                      <img src="../assets/icons/WelcomePage/tick-icon.svg" />
                    </div>
                  </div>
                  <p class="text-wrapper-31">
                    Integrasikan alat edutech pilihan anda menggunakan alat tambahan DELIMaFLiX <br />*tersedia dengan
                    Peningkatan Pengajaran dan Pembelajaran serta DELIMaFLiX Plus.
                  </p>
                </div>
              </div>
            </div>

          </div>
          <div class="group-35">
            <div class="overlap-13">
              <img class="rectangle-21" src="../assets/Images/Views/WelcomePage/mudah-digunakan-2.png" /> 
            </div>
          </div>
        </div>

        <!-- section 2.3 -->
        <div class="group-22">
          <div class="group-20">
            <div class="overlap-9">
              <img class="rectangle-3" src="../assets/Images/Views/WelcomePage/perkayakan-ilmu-pengetahuan-3.png" />
            </div>
          </div>
          <div class="frame-22">
            <div class="frame-20">
              <div class="text-wrapper-29">Perkayakan Ilmu Pengetahuan</div>
              <div class="text-wrapper-30">Meningkatkan pengalaman pembelajaran pelajar</div>
              <div class="frame-21">
                <div class="details">
                  <div class="done">
                    <div class="img-wrapper">
                      <img class="path" src="../assets/icons/WelcomePage/tick-icon.svg" />
                    </div>
                  </div>
                  <p class="text-wrapper-31">
                    Berikan pelajar kemampuan untuk menyesuaikan tetapan aksesibiliti agar mereka dapat belajar dengan
                    cara yang paling sesuai bagi mereka - biarpun dalam pelbagai bahasa.
                  </p>
                </div>
                <div class="details">
                  <div class="done">
                    <div class="img-wrapper">
                      <img class="path" src="../assets/icons/WelcomePage/tick-icon.svg" />
                    </div>
                  </div>
                  <p class="text-wrapper-31">
                    Membolehkan pelajar mengambil dan menghantar gambar tugasan rumah berdasarkan kertas mereka dengan cepat dan mudah dengan perakam imej yang terkini.
                  </p>
                </div>
                <div class="details">
                  <div class="done">
                    <div class="img-wrapper">
                      <img class="path" src="../assets/icons/WelcomePage/tick-icon.svg" />
                    </div>
                  </div>
                  <p class="text-wrapper-31">
                    Muat naik dokumen kerja kursus secara automatik sebagai templat untuk memberi setiap pelajar salinan tugas yang perlu dibuat.
                  </p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <!-- SECTION 2 - END -->

      <!-- SECTION 3 - GUNAKAN DELIMA FLIX -->
      <div class="terokai">
        <div class="terokai-left">
          <img class="terokai-img" src="../assets/Images/Views/WelcomePage/terokai-img.png" />
        </div>
        <div class="terokai-right">
          <p class="text-wrapper-terokai">
            Gunakan DELIMaFlix sebagai sebahagian daripada inisiatif Be Better oleh Kementerian Pendidikan Malaysia dan terokai pengalaman pendidikan yang inovatif.
          </p>
          <br>
        </div>
      </div>
      <!-- SECTION 3 - END -->

      <!-- SECTION FOOTER -->
      <div class="overlap-2">
        <div class="overlap-3">
          <div class="frame-10">
            <div class="frame-11">
              <div class="frame-3">
                <img src="../assets/icons/WelcomePage/delimaflix-logo-color.svg" class="header-logo"/>
              </div>
              <div class="menggabungkan-tempat">Menggabungkan tempat <br />pengajaran dan pembelajaran.</div>
              <div class="footer-list">
                <div>
                  <ul>
                    <li class="text-wrapper-17"><router-link :to="{'name' : 'Privacy Page'}" class="text-gray-500">Privasi & Polisi</router-link></li>
                    <li class="text-wrapper-17"><router-link :to="{'name' : 'Term Page'}" class="text-gray-500">Terma & Syarat</router-link></li>
                  </ul>
                </div>  
              </div>
            </div>

          </div>

          <div class="frame-10">
            <img class="phone-cikgu" src="../assets/Images/Views/WelcomePage/desktop-mobile-footer.png" />
          </div>
        </div>
      </div>
      <!-- SECTION FOOTER - END -->
    </div>
  </div>
</template>
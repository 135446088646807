<script>
export default {
  data() {
    return {
      selectedOption: '',
      isButtonDisabled: true,
    };
  },
  computed: {
    buttonClass() {
      return this.selectedOption ? 'button-active' : 'button-disabled';
    },
  },
  methods: {
    updateButton() {
      this.isButtonDisabled = this.selectedOption === '';
    },
    handleButtonClick() {
      if (this.selectedOption) {
        window.open(this.selectedOption, '_blank');
      }
    },
  },
};
</script>

<template>
    <div class="landing">
      <div class="center-content">

        <!-- SECTION - HERO -->
        <main class="sectionhero">
          <nav class="header">
            <div class="frame-3">
              <router-link :to="{'name' : 'Welcome Page'}" class="header-logo"><img src="../assets/icons/WelcomePage/delimaflix-logo-color1.svg" class="header-logo"/></router-link>
            </div>
            <div class="login-container">
              <div class="login-section">
                <select id="dropdown-container" class="btn-login" v-model="selectedOption" @change="updateButton">
                    <option value="" selected disabled>Pilih Kategori</option>
                    <option value="https://delimaflix.moe.gov.my/dashboard">Pelajar</option>
                    <option value="https://app-delima.moe.gov.my/mam/login">BSTP</option>
                    <option value="https://app-delima.moe.gov.my/mps/login">Guru</option>
                </select>
                <div class="icon-container">
                  <img src="../assets/icons/WelcomePage/icon-arrow-down.svg" class="icon-login"/>
                </div>
              </div>
              <div class="login-section">
                <button
                    id="loginButton"
                    :disabled="isButtonDisabled"
                    :class="buttonClass"
                    @click="handleButtonClick"
                >
                    <div class="text-wrapper-3 desktop-only">Log Masuk</div>
                    <img src="../assets/icons/WelcomePage/icon-login.svg" class="login-icon mobile-only"/>
                </button>
              </div>
            </div>
          </nav>

          <div class="frame">
            <div class="frame-2">
              <h1 class="delimaflix"><span class="text-wrapper">Privacy & Policy</span></h1>
              <p class="hero-text">
                DelimaFlix is dedicated to safeguarding the privacy and security of your information. This Privacy Policy elucidates how we collect, use, and protect your personal data when you interact with our website and the DelimaFlix application ("the Application"). It is imperative to read this policy thoroughly to comprehend our practices regarding your information.
              </p>
            </div>
            <div>
            </div>
          </div>
        </main>

      <!-- SECTION 1 - FAEDAH MENGGUNAKAN EDUVAULT -->
        <div class="section1">
            <!-- SECTION 4 - SUMBER BAHAN BELAJAR -->
            <div id="sumber-bahan-belajar-section" class="frame-18">
                <div>
                    <p class="text-wrapper-26">Information We Collect</p>
                </div>
                <div>
                    <p class="text-wrapper-27">
                    User-Provided Information: When you create an account, we gather your username, email address, and password to secure your account.

                    Automatically Collected Information: We may collect information about your usage of the Application, including pages visited, features used, and interactions.
                    
                    Google User Data: DelimaFlix may request and access your Google user data with your explicit consent. Importantly, we only access the necessary Google user data required for the proper functioning of the Application.
                                    
                    </p>
                </div>


                <div>
                    <p class="text-wrapper-26">How We Use Your Information</p>
                </div>
                <div>
                    <p class="text-wrapper-27">
                    We employ the collected information for various purposes, including providing and maintaining the Application, personalizing your experience, improving our services, and communicating important updates and notices. Additionally, we use the information to comply with legal obligations.     
                    </p>
                </div>


                <div>
                    <p class="text-wrapper-26">Information Sharing</p>
                </div>
                <div>
                    <p class="text-wrapper-27">
                    We are committed to not selling, trading, or otherwise transferring your personally identifiable information to third parties. However, information may be shared with your explicit consent, with trusted third-party service providers, or as required by law.
                    </p>
                </div>


                <div>
                    <p class="text-wrapper-26">Google User Data Usage</p>
                </div>
                <div>
                    <p class="text-wrapper-27">
                    DelimaFlix only accesses and uses the Google user data necessary for providing the core features of the Application. Any stored Google user data is securely encrypted and stored in compliance with industry standards.
                    </p>
                </div>


                <div>
                    <p class="text-wrapper-26">Your Choices</p>
                </div>
                <div>
                    <p class="text-wrapper-27">
                    You have control over your information. Account information can be reviewed and updated in the Application's settings, and you can manage Google data access through your Google account settings.
                    </p>
                </div>


                <div>
                    <p class="text-wrapper-26">Security</p>
                </div>
                <div>
                    <p class="text-wrapper-27">
                    We implement industry-standard security measures to protect your information. However, no method of transmission over the internet or electronic storage is completely secure.
                    </p>
                </div>


                <div>
                    <p class="text-wrapper-26">Changes to this Privacy Policy</p>
                </div>
                <div>
                    <p class="text-wrapper-27">
                    DelimaFlix reserves the right to update or modify this Privacy Policy, with users being notified of any changes by updating the "Last Updated" date.
                    </p>
                </div>
            </div>
        </div>

        <!-- SECTION 3 - GUNAKAN DELIMA FLIX -->
        <div class="terokai">
          <div class="terokai-left">
            <img class="terokai-img" src="../assets/Images/Views/WelcomePage/terokai-img.png" />
          </div>
          <div class="terokai-right">
            <p>
              Gunakan DELIMaFlix sebagai sebahagian daripada inisiatif Be Better oleh Kementerian Pendidikan Malaysia dan terokai pengalaman pendidikan yang inovatif.
            </p>
            <br>
          </div>
        </div>
        <!-- SECTION 3 - END -->

        <!-- SECTION FOOTER -->
        <div class="overlap-2">
            <div class="overlap-3">
                <div class="frame-10">
                    <div class="frame-11">
                    <div class="frame-3">
                        <img src="../assets/icons/WelcomePage/delimaflix-logo-color.svg" class="header-logo"/>
                    </div>
                    <div class="menggabungkan-tempat">Menggabungkan tempat <br />pengajaran dan pembelajaran.</div>
                        <div class="footer-list">
                            <div>
                                <ul>
                                    <li class="text-wrapper-17"><router-link :to="{'name' : 'Privacy Page'}" class="text-gray-500">Privasi & Polisi</router-link></li>
                                    <li class="text-wrapper-17"><router-link :to="{'name' : 'Term Page'}" class="text-gray-500">Terma & Syarat</router-link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="frame-10">
                    <img class="phone-cikgu" src="../assets/Images/Views/WelcomePage/desktop-mobile-footer.png" />
                </div>
            </div>
        </div>
        <!-- SECTION FOOTER - END -->
      </div>
    </div>
</template>
import { defineStore } from 'pinia';
export const usePlaybackStore = defineStore('playback', {
    state: () => ({
        currentVideoPlayerActive: null,
        currentTime: 0,
        duration: 0,
        isPlaying: false,
    }),
    actions: {
        updateCurrentVideoPlayerElement(videoPlayerElement) {
            this.currentVideoPlayerElement = videoPlayerElement;
          },     
        updateCurrentTime(time) {
            this.currentTime = time;
        },
        updateDuration(duration) {
            this.duration = duration;
        },
        updatePlayingState(isPlaying) {
            this.isPlaying = isPlaying;
        },
    },
    getters: {
        currentVideoPlayer() {
            if (this.currentVideoPlayerElement) {
                return this.currentVideoPlayerElement
            } 
            return null;
        },
    },
});
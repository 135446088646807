<script>
export default {
  data() {
    return {
      selectedOption: '',
      isButtonDisabled: true,
    };
  },
  computed: {
    buttonClass() {
      return this.selectedOption ? 'button-active' : 'button-disabled';
    },
  },
  methods: {
    updateButton() {
      this.isButtonDisabled = this.selectedOption === '';
    },
    handleButtonClick() {
      if (this.selectedOption) {
        window.open(this.selectedOption, '_blank');
      }
    },
  },
};
</script>

<template>
     <div class="landing">
      <div class="center-content">

        <!-- SECTION - HERO -->
        <main class="sectionhero">
          <nav class="header">
            <div class="frame-3">
              <router-link :to="{'name' : 'Welcome Page'}" class="header-logo"><img src="../assets/icons/WelcomePage/delimaflix-logo-color1.svg" class="header-logo"/></router-link>
            </div>
            <div class="login-container">
              <div class="login-section">
                <select id="dropdown-container" class="btn-login" v-model="selectedOption" @change="updateButton">
                    <option value="" selected disabled>Pilih Kategori</option>
                    <option value="https://delimaflix.moe.gov.my/dashboard">Pelajar</option>
                    <option value="https://app-delima.moe.gov.my/mam/login">BSTP</option>
                    <option value="https://app-delima.moe.gov.my/mps/login">Guru</option>
                </select>
                <div class="icon-container">
                  <img src="../assets/icons/WelcomePage/icon-arrow-down.svg" class="icon-login"/>
                </div>
              </div>
              <div class="login-section">
                <button
                    id="loginButton"
                    :disabled="isButtonDisabled"
                    :class="buttonClass"
                    @click="handleButtonClick"
                >
                    <div class="text-wrapper-3 desktop-only">Log Masuk</div>
                    <img src="../assets/icons/WelcomePage/icon-login.svg" class="login-icon mobile-only"/>
                </button>
              </div>
            </div>
          </nav>

          <div class="frame">
            <div class="frame-2">
              <h1 class="delimaflix"><span class="text-wrapper">Terms & Conditions</span></h1>
              <p class="hero-text">
                By accessing and using the DelimaFlix website and application ("the Service"), you agree to abide by the following Terms and Conditions. If you do not agree with these terms, please refrain from using the Service.
              </p>
            </div>
            <div>
            </div>
          </div>
        </main>



      <!-- SECTION 1 - FAEDAH MENGGUNAKAN EDUVAULT -->
        <div class="section1">
        <!-- SECTION 4 - SUMBER BAHAN BELAJAR -->
        <div id="sumber-bahan-belajar-section" class="frame-18">
          <div>
            <p class="text-wrapper-26"> Acceptance of Terms</p>
          </div>
          <div>
            <p class="text-wrapper-27">
              By using the Service, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions. DelimaFlix reserves the right to update, modify, or replace any part of these terms at its sole discretion. It is your responsibility to check for updates periodically. Continued use of the Service after modifications constitute acceptance of the updated terms.         
            </p>
          </div>


          <div>
            <p class="text-wrapper-26">Use of the Service</p>
          </div>
          <div>
            <p class="text-wrapper-27">
              You must be at least 18 years old or have parental consent to use the Service. You agree not to engage in any unlawful activities or activities that may interfere with the proper functioning of the Service. DelimaFlix may terminate or suspend your account if it believes you have violated these terms.
            </p>
          </div>


          <div>
            <p class="text-wrapper-26">Content</p>
          </div>
          <div>
            <p class="text-wrapper-27">
              Users are solely responsible for the content they upload, post, or share on DelimaFlix. By using the Service, you grant DelimaFlix a non-exclusive, worldwide, royalty-free license to use, reproduce, modify, adapt, publish, and distribute your content.
            </p>
          </div>


          <div>
            <p class="text-wrapper-26"> Intellectual Property</p>
          </div>
          <div>
            <p class="text-wrapper-27">
              The Service and its original content, features, and functionality are owned by DelimaFlix and are protected by international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.
            </p>
          </div>


          <div>
            <p class="text-wrapper-26">Privacy</p>
          </div>
          <div>
            <p class="text-wrapper-27">
              Your use of the Service is also governed by our Privacy Policy. By using the Service, you consent to the collection, use, and sharing of your information as outlined in the Privacy Policy.
            </p>
          </div>


          <div>
            <p class="text-wrapper-26"> Limitation of Liability</p>
          </div>
          <div>
            <p class="text-wrapper-27">
            DelimaFlix and its affiliates, officers, directors, employees, agents, and licensors shall not be liable for any direct, indirect, incidental, special, consequential, or exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data, or other intangible losses resulting from the use or inability to use the Service.
            </p>
          </div>


          <div>
            <p class="text-wrapper-26">Governing Law</p>
          </div>
          <div>
            <p class="text-wrapper-27">
              These Terms and Conditions are governed by and construed in accordance with the laws of our Jurisdiction. Any disputes arising from or in connection with these terms are subject to the exclusive jurisdiction of the courts in our Jurisdiction.
            </p>
          </div>
        </div>

        </div>

        <!-- SECTION 3 - GUNAKAN DELIMA FLIX -->
        <div class="terokai">
          <div class="terokai-left">
            <img class="terokai-img" src="../assets/Images/Views/WelcomePage/terokai-img.png" />
          </div>
          <div class="terokai-right">
            <p>
              Gunakan DELIMaFlix sebagai sebahagian daripada inisiatif Be Better oleh Kementerian Pendidikan Malaysia dan terokai pengalaman pendidikan yang inovatif.
            </p>
            <br>
           
          </div>
        </div>
        <!-- SECTION 3 - END -->

        <!-- SECTION FOOTER -->
        <div class="overlap-2">
          <div class="overlap-3">
            <div class="frame-10">
              <div class="frame-11">
                <div class="frame-3">
                  <img src="../assets/icons/WelcomePage/delimaflix-logo-color.svg" class="header-logo"/>
                </div>
                <div class="menggabungkan-tempat">Menggabungkan tempat <br />pengajaran dan pembelajaran.</div>
               
                <div class="footer-list">
                  <div>
                    <ul>
                        <li class="text-wrapper-17"><router-link :to="{'name' : 'Privacy Page'}" class="text-gray-500">Privasi & Polisi</router-link></li>
                        <li class="text-wrapper-17"><router-link :to="{'name' : 'Term Page'}" class="text-gray-500">Terma & Syarat</router-link></li>
                    </ul>
                  </div>
                  
                </div>
              </div>

            </div>

            <div class="frame-10">
              <img class="phone-cikgu" src="../assets/Images/Views/WelcomePage/desktop-mobile-footer.png" />
            </div>
          </div>
        </div>
        <!-- SECTION FOOTER - END -->
      </div>
    </div>
</template>
<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import { useModeStore } from '../store/mode.js';
import useIsMobile from '../composables/isMobile.js';
import { useAllSearchResultStore } from '../store/allSearchResult.js';
import { useWorkspaceStore } from '../store/workspace.js';
import { useNotificationTopBarStore } from '../store/notificationTopBar.js';
import { getContentTypeLabel } from '../utils/commonFunctions.js';
import { getStrokeColor, getModeStrokeColor } from '../utils/commonFunctions.js';
import { useRouter } from 'vue-router';

import i18next from 'i18next';

const modeStore = useModeStore();
const strokeColor = getStrokeColor();
const modeStrokeColor = getModeStrokeColor();
const { isMobile } = useIsMobile();
const allSearchResultStore = useAllSearchResultStore();
const workspaceStore = useWorkspaceStore();
const notificationTopBarStore = useNotificationTopBarStore();
const router = useRouter();
// const isHovered = ref(false);
const hoveredDocument = ref(null);
const isAddRemoveWorkspaceLoading = ref(false);

// Import components
import PlaylistCard from '../components/PlaylistCard.vue'
import TeachersCard from '../components/TeachersCard.vue'
import DocumentsCard from '../components/DocumentsCard.vue'
import NotificationTopBar from '../components/NotificationTopBar.vue';

const goToDocument = (id) => {
    router.push({ name: 'DocumentDetailed', params: { id: id } });
}

const addToWorkspace = async (id) => {
    try {
        isAddRemoveWorkspaceLoading.value = true;
        const response = await axios.post('api/v1/workspaces/asset_broadcasts', {
            asset_broadcast_id: id
        });
        // Find and update the document
        const document = allSearchResultStore.allSearchResult.assets.find(doc => doc.id === id);
        if (document) {
            document.has_added_to_workspace = true;
        }
        // Check if the response indicates success
        if (response.status === 200 || response.status === 201) { 
            // Set up notification after successful addition
            notificationTopBarStore.setIdUsedInAction(id);
            notificationTopBarStore.setNotificationMessage(i18next.t('notificationTopBar.addedToWorkspace'));
            notificationTopBarStore.setNotificationType('addAssetBroadcastToWorkspace');
            notificationTopBarStore.setIsShowNotificationTopBar(true);

            // Emit an event to refresh the asset API
            //emit('refresh-asset-api');
        } else {
            console.error('Failed to add asset broadcast to workspace:', response);
        }
    } catch (error) {
        console.error('Error adding asset broadcast to workspace:', error);
    } finally {
        isAddRemoveWorkspaceLoading.value = false;
    }
}

const removeFromWorkspace = async (id) => {
    try {
        isAddRemoveWorkspaceLoading.value = true;
        await axios.delete(`api/v1/workspaces/asset_broadcasts/${id}`);
        // Find and update the document
        const document = allSearchResultStore.allSearchResult.assets.find(doc => doc.id === id);
        if (document) {
            document.has_added_to_workspace = false;
        }

        notificationTopBarStore.setIdUsedInAction(id);
        notificationTopBarStore.setNotificationMessage(i18next.t('notificationTopBar.removedFromWorkspace'));
        notificationTopBarStore.setNotificationType('removeAssetBroadcastFromWorkspace');
        notificationTopBarStore.setIsShowNotificationTopBar(true);
    } catch (error) {
        console.error('Error removing playlist from workspace:', error);
    } finally {
        isAddRemoveWorkspaceLoading.value = false;
    }
}

const refreshPlaylistInAllSearchResult = async () => {
    try {
        if(workspaceStore.action === 'add') {
            const playlist = allSearchResultStore.allSearchResult.playlists.find(playlist => playlist.id === workspaceStore.playlistId);
            if (playlist) {
                playlist.has_added_to_workspace = true;
            }
        } else if(workspaceStore.action === 'remove') {
            const playlist = allSearchResultStore.allSearchResult.playlists.find(playlist => playlist.id === workspaceStore.playlistId);
            if (playlist) {
                playlist.has_added_to_workspace = false;
            }
        } else {
            console.error('Invalid workspace action:', workspaceStore.action);
        }
    } catch (error) {
        console.error('Error refreshing playlist in all search result:', error);
    }
}

const fetchAllSearchResult = () => {
    // Fetch data from API
    axios.get('api/v1/search', {
        params: {
            query: allSearchResultStore.searchQuery
        }
    })
    .then((response) => {
        allSearchResultStore.setAllSearchResult(response.data);
        // searchResultDocumentList.value = response.data.asset_broadcasts;
        // searchResultTeachers.value = response.data.users;
        allSearchResultStore.setIsSearchResultLoading(false);

    })
    .catch((error) => {
        console.error(error);
    })
}

onMounted(() => {
    fetchAllSearchResult();
});
</script>

<template>
    <div class="overflow-x-scroll hide-scrollbar">
        <NotificationTopBar v-show="notificationTopBarStore.isShowNotificationTopBar" />
        <div v-if="allSearchResultStore.isSearchResultLoading" class="flex items-center justify-center w-full gap-2 p-6">
            <div>{{ $t('general.loading')}}</div>
            <span class="loading loading-bars loading-sm"></span>
        </div>
        <section v-else class="rounded-xl p-4 m-4 bg-transparent" :class="{'text-black': modeStore.selectedMode === 'light', 'text-white': modeStore.selectedMode === 'dark'}">
            <div v-if="allSearchResultStore.allSearchResult.assets" class="md:grid md:grid-cols-12 gap-4">
                <div class="md:col-span-3 max-w-[300px]">
                    <div :class="{'text-black bg-[#E5E7EB]': modeStore.selectedMode === 'light', 'text-white bg-black': modeStore.selectedMode === 'dark'}" class="p-4 rounded-lg">
                        <h2 :class="{'mobile-title': isMobile, 'title': !isMobile}">{{ $t('search.topResult')}}</h2>
                        <DocumentsCard
                            class="mt-2"
                            :key="allSearchResultStore.allSearchResult.assets[0].id"
                            :id="allSearchResultStore.allSearchResult.assets[0].id"
                            :image="allSearchResultStore.allSearchResult.assets[0].thumbnails[0].url"
                            :topic="allSearchResultStore.allSearchResult.assets[0].title"
                            :groups="allSearchResultStore.allSearchResult.assets[0].media_group"
                            :subjects="allSearchResultStore.allSearchResult.assets[0].media_subgroup"
                            :postedTime="allSearchResultStore.allSearchResult.assets[0].created_at_formatted"
                            :tags="allSearchResultStore.allSearchResult.assets[0].content_type"
                            :isAddedToWorkspace="allSearchResultStore.allSearchResult.assets[0].has_added_to_workspace"
                            :contentType="allSearchResultStore.allSearchResult.assets[0].content_type"
                        />
                    </div>
                </div>
                <div class="md:col-span-9 min-w-[600px] overflow-x-scroll">
                    <div :class="{'text-black': modeStore.selectedMode === 'light', 'text-white': modeStore.selectedMode === 'dark'}" class="flex gap-2 py-3">
                        <router-link :to="{name: 'Document Page'}" class="title-action flex items-center">
                            <h2 :class="{'mobile-title': isMobile, 'title': !isMobile}">{{ $t('title.DELIMaCollection')}}</h2>
                            <i class="fa-solid fa-chevron-right fa-lg px-2"></i>
                        </router-link>
                    </div>
                    <!-- <div @click="goToDocument(document.id)"
                         v-for="document in allSearchResultStore.allSearchResult.asset_broadcasts" :key="document.id"
                        class="border-t py-4 cursor-pointer hover:bg-gray-50 hover:bg-opacity-10">
                        <div class="grid grid-cols-12">
                            <div class="col-span-8 flex gap-2">
                                <img :src="document.thumbnails[0].url" alt="course img" class="max-h-16 rounded-md">
                                <div class="my-auto">
                                    <p class="my-auto font-semibold">{{ document.title }}</p>
                                    <p class="text-sm">{{ document.courseLevel }}</p>
                                </div>
                            </div>
                            <div class="col-span-3 text-center my-auto">
                                <div             
                                :class="{
                                    'bg-[#FEF3C7] text-[#92400E]': document.content_type === 1,
                                    'bg-green-100 text-green-800': document.content_type === 2,
                                    'bg-gray-100 text-gray-800': document.content_type === 3
                                }" class="badge badge-lg border-0 rounded-xl">
                                    {{ getContentTypeLabel(document.content_type) }}
                                </div>
                            </div>
                            <div class="col-span-1 text-center my-auto">{{ document.courseDuration }}</div>
                        </div>
                    </div> -->
                    <table :class="{'text-black bg-white': modeStore.selectedMode === 'light', 'bg-black text-white': modeStore.selectedMode === 'dark'}" class="bg-opacity-30 rounded-xl p-[30px] min-w-[600px]">
                        <thead class="text-base font-semibold rounded-t-xl" :class="{' bg-white bg-opacity-30': modeStore.selectedMode === 'light', 'bg-black bg-opacity-30': modeStore.selectedMode === 'dark'}">
                            <tr class="grid grid-cols-12 py-2 px-6 rounded-t-xl min-w-[600px]">
                                <th class="col-span-6 text-center">{{ $t('playlistPage.title')}}</th>
                                <!-- <th class="hidden md:block col-span-3 text-center">Dimuatkan oleh</th> -->
                                <th class="col-span-3 text-center">{{ $t('playlistPage.fileType')}}</th>
                                <th class="col-span-3 text-center">{{ $t('playlistPage.action')}}</th>
                            </tr>
                        </thead>
                        <div v-for="document in allSearchResultStore.allSearchResult.assets" :key="document.id" class="min-w-[600px]">
                            <div class="grid grid-cols-12 py-3 md:px-6 w-full" :class="{' bg-white bg-opacity-30 hover:bg-opacity-10': modeStore.selectedMode === 'light', 'bg-black bg-opacity-30 hover:bg-opacity-10': modeStore.selectedMode === 'dark'}">
                                <div class="col-span-6 flex gap-4">
                                    <div class="min-w-12 w-12 h-12 rounded-full">
                                        <img @click.prevent="goToDocument(document.id)" :src="document.thumbnails[0]?.url" alt="playlist image" class="w-full h-full object-cover cursor-pointer">
                                    </div>
                                    <div class="my-auto">
                                        <p class="text-sm md:text-base font-semibold">{{ document.title }}</p>
                                        <div >
                                            <span v-if="document.media_group && document.media_subgroup && document.media_group.length && document.media_subgroup.length">
                        {{ document.media_group.map(group => group.name).join(' | ') }} | {{ document.media_subgroup.map(subgroup => subgroup.name).join(' | ') }}
                    </span>
                    <span v-else-if="document.media_group && document.media_group.length">
                        {{ document.media_group.map(group => group.name).join(' | ') }}
                    </span>
                    <span v-else-if="document.media_subgroup && document.media_subgroup.length">
                        {{ document.media_subgroup.map(subgroup => subgroup.name).join(' | ') }}
                    </span>
                                        </div>
                                        
                                    </div>
                                </div>
                                <!-- <td class="col-span-3 text-center">
                                    <p v-if="document.created_by" class="text-sm">{{ document.created_by?.name }}</p>
                                    <p v-else class="text-sm">-</p>
                                </td> -->
                                <td class="col-span-3 text-center my-auto">
                                    <div             
                                :class="{
                                    'bg-[#FEF3C7] text-[#92400E]': document.content_type === 1,
                                    'bg-green-100 text-green-800': document.content_type === 2,
                                    'bg-gray-100 text-gray-800': document.content_type === 3
                                }" class="badge badge-lg border-0 rounded-xl">
                                    {{ getContentTypeLabel(document.content_type) }}
                                </div>
                                </td>
                                <td class="col-span-3 text-center my-auto mx-auto">
                                    <div v-if="document.has_added_to_workspace" @click="removeFromWorkspace(document.id)"  class="my-auto cursor-pointer">
                                        <div v-show="isAddRemoveWorkspaceLoading" class="spinner" :style="{borderLeftColor: strokeColor}"></div>
                                        <svg v-show="!isAddRemoveWorkspaceLoading" xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                                            <path d="M17.6257 3.73047C15.7402 3.73047 14.0527 4.64714 13.0007 6.05339C11.9486 4.64714 10.2611 3.73047 8.37565 3.73047C5.17773 3.73047 2.58398 6.33464 2.58398 9.55338C2.58398 10.793 2.7819 11.9388 3.12565 13.0013C4.77148 18.2096 9.8444 21.3242 12.3548 22.1784C12.709 22.3034 13.2923 22.3034 13.6465 22.1784C16.1569 21.3242 21.2298 18.2096 22.8757 13.0013C23.2194 11.9388 23.4173 10.793 23.4173 9.55338C23.4173 6.33464 20.8236 3.73047 17.6257 3.73047Z" :fill="strokeColor"/>
                                        </svg>
                                    </div>
                                    <div v-else @click="addToWorkspace(document.id)" @mouseover="hoveredDocument = document.id" @mouseout="hoveredDocument = null" class="my-auto cursor-pointer text-black">
                                        <div v-show="isAddRemoveWorkspaceLoading" class="spinner" :style="{borderLeftColor: strokeColor}"></div>
                                        <svg v-show="!isAddRemoveWorkspaceLoading" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                            <path d="M13.1465 21.6798C12.7923 21.8048 12.209 21.8048 11.8548 21.6798C8.83398 20.6486 2.08398 16.3465 2.08398 9.05485C2.08398 5.8361 4.67773 3.23193 7.87565 3.23193C9.77148 3.23193 11.4486 4.1486 12.5006 5.56527C13.5527 4.1486 15.2402 3.23193 17.1256 3.23193C20.3236 3.23193 22.9173 5.8361 22.9173 9.05485C22.9173 16.3465 16.1673 20.6486 13.1465 21.6798Z" :stroke="isHovered ? strokeColor : modeStrokeColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </div>
                                </td>
                            </div>
                        </div>
                    </table>
                </div>
            </div>

            <!--senarai cikgu for view all search result-->
            <div v-if="allSearchResultStore.allSearchResult.users" class="pt-6">
                <div 
                    class="flex items-center mb-4 gap-2"
                    :class="{'text-black': modeStore.selectedMode === 'light', 'text-white': modeStore.selectedMode === 'dark'}"
                >
                    <router-link to="/teachers" class="title-action flex items-center">
                        <h2 :class="{'mobile-title': isMobile, 'title': !isMobile}">{{ $t('title.teacherList')}}</h2>
                        <i class="fa-solid fa-chevron-right fa-lg px-2"></i>
                    </router-link>
                </div>

                <div 
                    class="grid grid-cols-3 gap-x-4 gap-y-12 md:grid-cols-7 md:gap-4 justify-items-center"
                    :class="{'avatar-mobile-container' : isMobile}"
                >
                    <TeachersCard
                        class="md:min-w-0 lg:w-[80%]"
                        v-for="teacher in allSearchResultStore.allSearchResult.users"
                        :key="teacher.id"
                        :image="teacher.avatar_url"
                        :name="teacher.name"
                        :role="'Cikgu'"
                        :id="teacher.id"
                    />
                </div>
            </div>


            <!-- Playlist for view all search result -->
            <div v-if="allSearchResultStore.allSearchResult.playlists" class="pt-6">
                <div 
                    class="flex items-center mb-4 gap-2"
                    :class="{'text-black': modeStore.selectedMode === 'light', 'text-white': modeStore.selectedMode === 'dark'}"
                >
                    <router-link :to="{name: 'Playlist Page'}" class="title-action flex items-center">
                        <h2 :class="{'mobile-title': isMobile, 'title': !isMobile}">Playlist</h2>
                        <i class="fa-solid fa-chevron-right fa-lg px-2"></i>
                    </router-link>
                </div>

                <div class="flex flex-row overflow-x-auto hide-scrollbar md:grid md:grid-cols-3 justify-items-center gap-4">
                    <PlaylistCard
                        class="min-w-full md:min-w-0 lg:w-full"
                        v-for="playlist in allSearchResultStore.allSearchResult.playlists"
                        :playlistId="playlist.id"
                        :key="playlist.id"
                        :image="playlist.playlist_thumbnail"
                        :subject="playlist.title"
                        :teacher="playlist.created_by?.name"
                        :grade="playlist.access_groups"
                        :documentCount="playlist.documentCount"
                        :interactionCount="playlist.interactive_count"
                        :videoCount="playlist.video_count"
                        :isAddedToWorkspace="playlist.has_added_to_workspace"
                        @refresh-playlist-api="refreshPlaylistInAllSearchResult"
                    />
                </div>
            </div>
        </section>
    </div>
</template>
<script setup>
import axios from 'axios';
import { computed, ref, watch, reactive, onMounted } from 'vue'
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators'
import { useModeStore } from '../store/mode.js';
import { useThemeStore } from '../store/theme.js';
import { useActivePageStore } from '../store/activePageTab.js';
// import { useAllSearchResultStore } from '../store/allSearchResult.js';
import { getModeStrokeColor } from '../utils/commonFunctions.js';
import TiptapEditor from '../components/TiptapEditor.vue';
import CustomPagination from '../components/CustomPagination.vue';
import PageNavigator from '../components/PageNavigator.vue';
import { useTranslation } from "i18next-vue";

const { t } = useTranslation();
const modeStore = useModeStore();
const themeStore = useThemeStore();
// const allSearchResultStore = useAllSearchResultStore();
const modeStrokeColor = getModeStrokeColor();
const activePageStore = useActivePageStore();

// const isFilterDisplay = ref(true);
// const filterByDocumentType = ref('all');
// const filterByUploadedBy = ref('admin');
// const filterByCourseLevel = ref('all');
// const filterBySubject = ref('all');
// const filterByDate = ref();
const currentView = ref('');
// const showSearchResult = ref(false);
// const searchQuery = ref('');
// const searchResults = ref([]);
const receiver = ref('');
const subject = ref('');
const editorContent = ref('');
// const isStarred = ref(false);
// const isArchived = ref(false);
const selectAll = ref(false);

const currentPage = ref(1);
const itemsPerPage = ref(12); // Default itemsPerPage 10
const totalItems = ref(0);

const isInboxEmpty = ref(true);
const inboxData = ref([]);
const isLoadingInbox = ref(false);
const isSentMailEmpty = ref(true);
const sentMailData = ref([]);
const isLoadingSentMail = ref(false);
const isArchiveEmpty = ref(true);
const archiveData = ref([]);
const isLoadingArchive = ref(false);
const kepadaOptions = ref([]);
const isLoadingKepadaOptions = ref(false);
const isLoadingMessageDetail = ref(false);
const messageDetail = ref(null);

const inboxNotificationCount = ref(0);
const sentMailNotificationCount = ref(0);
const archiveNotificationCount = ref(0);

const showingFrom = computed(() => {
    return (currentPage.value - 1) * itemsPerPage.value + 1;
});

const showingTo = computed(() => {
    // If this is the last page, return the total items, else return the current page * items per page
    return totalItems.value < currentPage.value * itemsPerPage.value ? totalItems.value : currentPage.value * itemsPerPage.value;
});

const totalPages = computed(() => {
    return Math.ceil(totalItems.value / itemsPerPage.value);
});

const changePage = (page) => {
    currentPage.value = page;
    window.alert('Change page');
    // call API with new page as query param
    // isLoading.value = true;
    // axios.get(`api/v1/&page=${page}`)
    //     .then((response) => {
    //         currentPage.value = response.data.current_page;
    //         showingTo.value = response.data.to;
    //         totalItems.value = response.data.total;
    //         data.value = response.data.data;
    //     })
    //     .catch((error) => {
    //         console.error('Failed to fetch API data:', error);
    //     })
    //     .finally(() => {
    //         isLoading.value = false;
    //     });
};

// Vuelidate setup
const state = reactive({
  receiver: receiver.value,
  subject: subject.value,
//   editorContent: editorContent.value,
});

const rules = {
  receiver: { required },
  subject: { required },
//   editorContent: { required },
};

const v$ = useVuelidate(rules, state);
// Mock data
// const searchData = [
//     {
//         id: 1,
//         title: 'Maklum Balas 1',
//     }
// ];

const changeView = (view, id) => {
    currentView.value = view;

    if (view === 'inbox') {
        // get api/v1/messages?sent_by_me=0
        isLoadingInbox.value = true;
        axios.get('api/v1/messages?sent_by_me=0').then((response) => {
            if (response.data.meta.total != 0) {
                isInboxEmpty.value = false;
                inboxData.value = response.data.data.map(inbox => ({
                    ...inbox,
                    selected: false
                }));
            }
        }).catch(error => {
            console.error('Failed to fetch inbox data:', error);
        }).finally(() => {
            isLoadingInbox.value = false;
        });
    }

    if (view === 'sent-mail') {
        // get api/v1/messages?sent_by_me=true
        isLoadingSentMail.value = true;
        axios.get('api/v1/messages?sent_by_me=true').then((response) => {
            if (response.data.meta.total != 0) {
                isSentMailEmpty.value = false;
                sentMailData.value = response.data.data.map(sentMail => ({
                    ...sentMail,
                    selected: false
                }));
            }
        }).catch(error => {
            console.error('Failed to fetch sent mail data:', error);
        }).finally(() => {
            isLoadingSentMail.value = false;
        });
    }

    if (view === 'archive') {
        // get api/v1/messages?archived_only=1
        isLoadingArchive.value = true;
        axios.get('api/v1/messages?archived_only=1').then((response) => {
            if (response.data.meta.total != 0) {
                isArchiveEmpty.value = false;
                archiveData.value = response.data.data.map(archive => ({
                    ...archive,
                    selected: false
                }));
            }
        }).catch(error => {
            console.error('Failed to fetch archive data:', error);
        }).finally(() => {
            isLoadingArchive.value = false;
        });
    }

    if (view === 'writeMessage') {
        // get api/v1/users?role=cikgu
        isLoadingKepadaOptions.value = true;
        axios.get('api/v1/users?role=cikgu').then((response) => {
            kepadaOptions.value = response.data.data.map(teacher => ({
                id: teacher.id,
                name: teacher.name
            }));
        }).catch(error => {
            console.error('Failed to fetch kepada option data:', error);
        }).finally(() => {
            isLoadingKepadaOptions.value = false;
        });
    }

    if (view === 'message-detail') {
        isLoadingMessageDetail.value = true;
        // get api/v1/messages/{id}
        axios.get(`api/v1/messages/${id}`).then((response) => {
            messageDetail.value = response.data.data;
        }).catch(error => {
            console.error('Failed to fetch message detail:', error);
        }).finally(() => {
            isLoadingMessageDetail.value = false;
        });
    }
};

const refreshContent = () => {
    switch (currentView.value) {
        case 'inbox':
            // get api/v1/messages
            isLoadingInbox.value = true;
            axios.get('api/v1/messages').then((response) => {
                inboxData.value = response.data.data;
            }).catch(error => {
                console.error('Failed to fetch inbox data:', error);
            }).finally(() => {
                isLoadingInbox.value = false;
            });
            break;
        case 'sent-mail':
            // get api/v1/messages?sent_by_me=true
            isLoadingSentMail.value = true;
            axios.get('api/v1/messages?sent_by_me=true').then((response) => {
                sentMailData.value = response.data.data;
            }).catch(error => {
                console.error('Failed to fetch sent mail data:', error);
            }).finally(() => {
                isLoadingSentMail.value = false;
            });
            break;
        case 'archive':
            // get api/v1/messages?archived_only=1
            isLoadingArchive.value = true;
            axios.get('api/v1/messages?archived_only=1').then((response) => {
                archiveData.value = response.data.data;
            }).catch(error => {
                console.error('Failed to fetch archive data:', error);
            }).finally(() => {
                isLoadingArchive.value = false;
            });
            break;
    }
};

const retrieveAllMessages = () => {
    switch (currentView.value) {
        case 'inbox':
            // get api/v1/messages
            isLoadingInbox.value = true;
            axios.get('api/v1/messages').then((response) => {
                inboxData.value = response.data.data;
            }).catch(error => {
                console.error('Failed to fetch inbox data:', error);
            }).finally(() => {
                isLoadingInbox.value = false;
            });
            break;
        case 'sent-mail':
            // get api/v1/messages?sent_by_me=true
            isLoadingSentMail.value = true;
            axios.get('api/v1/messages?sent_by_me=true').then((response) => {
                sentMailData.value = response.data.data;
            }).catch(error => {
                console.error('Failed to fetch sent mail data:', error);
            }).finally(() => {
                isLoadingSentMail.value = false;
            });
            break;
        case 'archive':
            // get api/v1/messages?archived_only=1
            isLoadingArchive.value = true;
            axios.get('api/v1/messages?archived_only=1').then((response) => {
                archiveData.value = response.data.data;
            }).catch(error => {
                console.error('Failed to fetch archive data:', error);
            }).finally(() => {
                isLoadingArchive.value = false;
            });
            break;
    }
}

const retrieveReadMessages = () => {
    switch (currentView.value) {
        case 'inbox':
            // get api/v1/messages?unread=0
            isLoadingInbox.value = true;
            axios.get('api/v1/messages?unread=0').then((response) => {
                inboxData.value = response.data.data;
            }).catch(error => {
                console.error('Failed to fetch read inbox data:', error);
            }).finally(() => {
                isLoadingInbox.value = false;
            });
            break;
        case 'sent-mail':
            // get api/v1/messages?sent_by_me=true&unread=0
            isLoadingSentMail.value = true;
            axios.get('api/v1/messages?sent_by_me=true&unread=0').then((response) => {
                sentMailData.value = response.data.data;
            }).catch(error => {
                console.error('Failed to fetch read sent mail data:', error);
            }).finally(() => {
                isLoadingSentMail.value = false;
            });
            break;
        case 'archive':
            // get api/v1/messages?archived_only=1&unread=0
            isLoadingArchive.value = true;
            axios.get('api/v1/messages?archived_only=1&unread=0').then((response) => {
                archiveData.value = response.data.data;
            }).catch(error => {
                console.error('Failed to fetch read archive data:', error);
            }).finally(() => {
                isLoadingArchive.value = false;
            });
            break;
    }
}

const retrieveUnreadMessages = () => {
    switch (currentView.value) {
        case 'inbox':
            // get api/v1/messages?unread=1
            isLoadingInbox.value = true;
            axios.get('api/v1/messages?unread=1').then((response) => {
                inboxData.value = response.data.data;
            }).catch(error => {
                console.error('Failed to fetch unread inbox data:', error);
            }).finally(() => {
                isLoadingInbox.value = false;
            });
            break;
        case 'sent-mail':
            // get api/v1/messages?sent_by_me=true&unread=1
            isLoadingSentMail.value = true;
            axios.get('api/v1/messages?sent_by_me=true&unread=1').then((response) => {
                sentMailData.value = response.data.data;
            }).catch(error => {
                console.error('Failed to fetch unread sent mail data:', error);
            }).finally(() => {
                isLoadingSentMail.value = false;
            });
            break;
        case 'archive':
            // get api/v1/messages?archived_only=1&unread=1
            isLoadingArchive.value = true;
            axios.get('api/v1/messages?archived_only=1&unread=1').then((response) => {
                archiveData.value = response.data.data;
            }).catch(error => {
                console.error('Failed to fetch unread archive data:', error);
            }).finally(() => {
                isLoadingArchive.value = false;
            });
            break;
    }
}

const SendMessage = () => {
    // Validate form
    v$.value.$touch();

    if (!v$.value.$error) {
        // post api/v1/messages
        axios.post('api/v1/messages', {
            to_user_id: state.receiver,
            subject: state.subject,
            content: editorContent.value,
            // message_type: 'text',
        }).then(() => {
            changeView('sent-mail'); // Redirect to SentMail view
        }).catch((error) => {
            console.error('Failed to send message:', error);
        });
    }
}

// const viewAllSearchResult = () => {
//     allSearchResultStore.setShowAllSearchResult(true);
// };

// const toggleFilter = () => {
//     isFilterDisplay.value = !isFilterDisplay.value;
// };

// const resetFilter = () => {
//     filterByDocumentType.value = 'all';
//     filterByUploadedBy.value = 'admin';
//     filterByCourseLevel.value = 'all';
//     filterBySubject.value = 'all';
//     filterByDate.value = '';
// }

// const truncatedInboxData = computed(() => {
//     return inboxData.value.map(inbox => ({
//         ...inbox,
//         truncatedContent: inbox.content && inbox.content.length > 15 ? (inbox.content.substring(0, 15) + '...') : inbox.content
//       }));
// });

// const truncatedSentMailData = computed(() => {
//     return sentMailData.value.map(sentMail => ({
//         ...sentMail,
//         truncatedContent: sentMail.content && sentMail.content.length > 15 ? (sentMail.content.substring(0, 15) + '...') : sentMail.content,
//       }));
// });

// const truncatedArchiveData = computed(() => {
//     return archiveData.value.map(archive => ({
//         ...archive,
//         truncatedContent: archive.content && archive.content.length > 15 ? archive.content.substring(0, 15) + '...' : archive.content,
//       }));
// });

const sectionTitle = computed(() => {
    switch (currentView.value) {
        case 'inbox':
            return t('feedback.inbox');
        case 'sent-mail':
            return t('feedback.sentMail');
        case 'archive':
            return t('feedback.archive');
        case 'writeMessage':
            return t('feedback.writeMessage');
        default:
            return t('feedback.inbox');
    }
});

// // Function to perform search
// const performSearch = () => {
//   if (searchQuery.value.trim() !== '') {
//     showSearchResult.value = true;
//     searchResults.value = searchData.filter(item =>
//       item.title.toLowerCase().includes(searchQuery.value.toLowerCase())
//     );
//   } else {
//     showSearchResult.value = false;
//     searchResults.value = [];
//   }
// };

const selectedInboxes = computed(() => {
    return inboxData.value.filter(inbox => inbox.selected);
});

const selectedSentMails = computed(() => {
    return sentMailData.value.filter(sentMail => sentMail.selected);
});

const selectedArchives = computed(() => {
    return archiveData.value.filter(archive => archive.selected);
});

const markAllRead = () => {
    let selectedIds; 
    
    switch (currentView.value) {
        case 'inbox':
            selectedIds = selectedInboxes.value.map(inbox => inbox.id).join(',');
            break;
        case 'sent-mail':
            selectedIds = selectedSentMails.value.map(sentMail => sentMail.id).join(',');
            break;
        case 'archive':
            selectedIds = selectedArchives.value.map(archive => archive.id).join(',');
            break;
    }

    // POST api/v1/messages/read
    axios.post('api/v1/messages/read', {
        message_ids: selectedIds
    }).then(() => {
        refreshContent();
        selectAll.value = false;
    }).catch((error) => {
        console.error('Failed to mark all read:', error);
    });
}

const markAllUnread = () => {
    let selectedIds;

    switch (currentView.value) {
        case 'inbox':
            selectedIds = selectedInboxes.value.map(inbox => inbox.id).join(',');
            break;
        case 'sent-mail':
            selectedIds = selectedSentMails.value.map(sentMail => sentMail.id).join(',');
            break;
        case 'archive':
            selectedIds = selectedArchives.value.map(archive => archive.id).join(',');
            break;
    }

    // POST api/v1/messages/unread
    axios.post('api/v1/messages/unread', {
        message_ids: selectedIds
    }).then(() => {
        refreshContent();
        selectAll.value = false;
    }).catch((error) => {
        console.error('Failed to mark all unread:', error);
    });
}

// Watch for changes in searchQuery to update search results
// watch(searchQuery, () => {
//   performSearch();
// });

watch(selectAll, (newValue) => {
    if (currentView.value === "inbox") {
        inboxData.value.forEach(inbox => inbox.selected = newValue);
    }

    if (currentView.value === "sent-mail") {
        sentMailData.value.forEach(sentMail => sentMail.selected = newValue);
    }

    if (currentView.value === "archive") {
        archiveData.value.forEach(archive => archive.selected = newValue);
    }
});

onMounted(() => {
    // get api/v1/messages/counters
    axios.get('api/v1/messages/counters').then((response) => {
        inboxNotificationCount.value = response.data.unread_count;
        sentMailNotificationCount.value = response.data.sent_by_me_count;
        archiveNotificationCount.value = response.data.archived_count;
    }).catch(error => {
        console.error('Failed to fetch notification counters:', error);
    });

    changeView('writeMessage');
    activePageStore.setSelectedActivePage(8)
});

// const truncatedContent = (content) => {
//     return content && content.length > 1 ? content.substring(0, 1) + '...' : content;
// }
</script>

<template>
    <div class="min-h-screen">
        <page-navigator from="Laman Utama" to="Maklum Balas" />
        <div :class="{'text-black': modeStore.selectedMode === 'light', ' text-white': modeStore.selectedMode === 'dark'}" class="flex justify-between justify-items-center my-auto py-4 px-[30px]">
            <h2 :class="{'mobile-title': isMobile, 'page-top-title': !isMobile}">{{ $t('feedback.feedback')}}</h2>
        </div>

        <section>
            <div 
                class="flex flex-col gap-8 rounded-xl p-[30px]"
                :class="{'bg-white bg-opacity-40 text-black': modeStore.selectedMode === 'light', 'bg-transparent text-white': modeStore.selectedMode === 'dark'}"
            >

                <div class="flex justify-between justify-items-center my-auto">
                    <h2 :class="{'mobile-title': isMobile, 'title': !isMobile}">{{ sectionTitle }}</h2>
                    <div class="my-auto h-10">
                        <div class="flex gap-4 justify-between h-10 my-auto">
                            <!-- Search -->
                            <!-- <div v-show="currentView !== 'writeMessage'" class="my-auto relative"> -->
                                <!-- <div :class="modeStore.selectedMode === 'dark' ? 'border-white' : 'border-black'" class="flex justify-between border rounded-lg cursor-pointer">
                                    <input type="text" :placeholder="$t('search.startSearch')" class=" px-4 py-2 bg-transparent focus:outline-none cursor-pointer" v-model="searchQuery" @input="performSearch">
                                    <svg class="my-auto mr-4" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.5 21.5C16.7467 21.5 21 17.2467 21 12C21 6.75329 16.7467 2.5 11.5 2.5C6.25329 2.5 2 6.75329 2 12C2 17.2467 6.25329 21.5 11.5 21.5Z" :stroke="modeStrokeColor" stroke-width="1.61" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M22 22.5L20 20.5" :stroke="modeStrokeColor" stroke-width="1.61" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </div> -->
                                <!-- result -->
                                <!-- <div v-if="showSearchResult && searchResults.length > 0" class="absolute w-full top-12 bg-white p-2 rounded-md" style="z-index: 1 !important">
                                    <div v-for="result in searchResults" :key="result.id" class="flex border-b py-1">
                                        <img :src="result.image" :alt="result.title" class="h-10 py-2">
                                        <div class="px-2">
                                            <p class="text-sm">{{ result.title }}</p>
                                            <p class="text-xs text-grey-800">{{ result.author }}</p>
                                        </div>
                                    </div>
                                    <div class="p-2">
                                        <button @click="viewAllSearchResult()" class="w-full bg-gray-100 rounded-md text-base p-1">
                                            {{ $t('search.viewAllResults')}}
                                        </button>
                                    </div>
                                </div> -->
                            <!-- </div> -->

                            <!-- Filter -->
                            <!-- <div v-show="currentView !== 'writeMessage' && currentView !== 'archive'" @click="toggleFilter()" class="w-8 my-auto cursor-pointer">
                                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.75 2.62402H23.25C24.625 2.62402 25.75 3.74902 25.75 5.12402V7.87402C25.75 8.87402 25.125 10.124 24.5 10.749L19.125 15.499C18.375 16.124 17.875 17.374 17.875 18.374V23.749C17.875 24.499 17.375 25.499 16.75 25.874L15 26.999C13.375 27.999 11.125 26.874 11.125 24.874V18.249C11.125 17.374 10.625 16.249 10.125 15.624L5.375 10.624C4.75 9.99902 4.25 8.87402 4.25 8.12402V5.24902C4.25 3.74902 5.375 2.62402 6.75 2.62402Z" :stroke="modeStrokeColor" stroke-width="2.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div> -->
                        </div>
                    </div>
                </div>

                <div class="flex flex-col lg:flex-row items-start justify-center gap-2">
                    <!-- Mailbox -->
                    <div 
                        class="flex flex-col justify-center w-full lg:basis-1/5 gap-4 rounded-xl p-4 bg-opacity-20"
                        :class="{'bg-custom-section-light-gray': modeStore.selectedMode === 'light', 'bg-custom-section-dark-gray': modeStore.selectedMode === 'dark'}"
                    >
                        <!-- <h4 class="text-lg leading-6 font-medium">Peti Mel</h4> -->
                        <div class="flex flex-col gap-5">
                            <!-- Inbox -->
                            <div 
                                class="flex flex-row items-center justify-center rounded-md p-2 h-11"
                                @click="changeView('inbox')"
                                :class="currentView === 'inbox' ? (modeStore.selectedMode === 'light' ? 'bg-gray-100' : 'bg-[#505358]') : ''"
                            >
                                <div class="basis-1/5">
                                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M20 7.5V16.25L22.5 13.75" :stroke="modeStrokeColor" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M20.0039 16.25L17.5039 13.75" :stroke="modeStrokeColor" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M7.47656 21.25H12.9891C13.4641 21.25 13.8891 21.5125 14.1016 21.9375L15.5641 24.8625C15.9891 25.7125 16.8516 26.25 17.8016 26.25H22.2141C23.1641 26.25 24.0266 25.7125 24.4516 24.8625L25.9141 21.9375C26.1266 21.5125 26.5641 21.25 27.0266 21.25H32.4766" :stroke="modeStrokeColor" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M13.7539 10.1621C9.32891 10.8121 7.50391 13.4121 7.50391 18.7496V23.7496C7.50391 29.9996 10.0039 32.4996 16.2539 32.4996H23.7539C30.0039 32.4996 32.5039 29.9996 32.5039 23.7496V18.7496C32.5039 13.4121 30.6789 10.8121 26.2539 10.1621" :stroke="modeStrokeColor" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </div>

                                <div class="basis-3/5 text-base leading-6 font-medium" :class="modeStore.selectedMode === 'dark' ? (currentView === 'inbox' ? 'text-gray-300' : 'text-white') : 'text-gray-600'">{{ $t('feedback.inbox')}}</div>

                                <div class="basis-1/5 flex items-center justify-center">
                                    <div 
                                        v-show="inboxNotificationCount > 0"
                                        class="badge border-none rounded-lg" 
                                        :class=" modeStore.selectedMode === 'dark' ? (currentView === 'inbox' ? 'text-white bg-[#737579]' : 'text-white bg-custom-badge-dark') : 'text-gray-600 bg-custom-badge-light'"
                                    >
                                        {{ inboxNotificationCount }}
                                    </div>
                                </div>
                            </div>

                            <!-- Sent Mail -->
                            <div 
                                class="flex flex-row items-center justify-center rounded-md p-2 h-11"
                                @click="changeView('sent-mail')"
                                :class="currentView === 'sent-mail' ? (modeStore.selectedMode === 'light' ? 'bg-gray-100' : 'bg-[#505358]') : ''"
                            >
                                <div class="basis-1/5">
                                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M20 16.25V7.5L17.5 10" :stroke="modeStrokeColor" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M20 7.5L22.5 10" :stroke="modeStrokeColor" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M7.47266 21.25H12.9852C13.4602 21.25 13.8852 21.5125 14.0977 21.9375L15.5602 24.8625C15.9852 25.7125 16.8477 26.25 17.7977 26.25H22.2102C23.1602 26.25 24.0227 25.7125 24.4477 24.8625L25.9102 21.9375C26.1227 21.5125 26.5602 21.25 27.0227 21.25H32.4727" :stroke="modeStrokeColor" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M13.75 11.4121C9.325 12.0621 7.5 14.6621 7.5 19.9996V23.7496C7.5 29.9996 10 32.4996 16.25 32.4996H23.75C30 32.4996 32.5 29.9996 32.5 23.7496V19.9996C32.5 14.6621 30.675 12.0621 26.25 11.4121" :stroke="modeStrokeColor" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </div>

                                <div class="basis-3/5 text-base leading-6 font-medium" :class="modeStore.selectedMode === 'dark' ? (currentView === 'sent-mail' ? 'text-gray-300' : 'text-white') : 'text-gray-600'">{{ $t('feedback.sentMail')}}</div>

                                <div class="basis-1/5 flex items-center justify-center">
                                    <div 
                                        v-show="sentMailNotificationCount > 0"
                                        class="badge border-none rounded-lg" 
                                        :class=" modeStore.selectedMode === 'dark' ? (currentView === 'sent-mail' ? 'text-white bg-[#737579]' : 'text-white bg-custom-badge-dark') : 'text-gray-600 bg-custom-badge-light'"
                                    >
                                        {{ sentMailNotificationCount }}
                                    </div>
                                </div>
                            </div>

                            <!-- Archive -->
                            <div 
                                class="flex flex-row items-center justify-center rounded-md p-2 h-11"
                                @click="changeView('archive')"
                                :class="currentView === 'archive' ? (modeStore.selectedMode === 'light' ? 'bg-gray-100' : 'bg-[#505358]') : ''"
                            >
                                <div class="basis-1/5">
                                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16.25 32.5H23.75C30 32.5 32.5 30 32.5 23.75V16.25C32.5 10 30 7.5 23.75 7.5H16.25C10 7.5 7.5 10 7.5 16.25V23.75C7.5 30 10 32.5 16.25 32.5Z" :stroke="modeStrokeColor" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M27.4961 14.6875V23.125C27.4961 21.75 26.3711 20.625 24.9961 20.625H14.9961C13.6211 20.625 12.4961 21.75 12.4961 23.125V14.6875C12.4961 13.3125 13.6211 12.1875 14.9961 12.1875H24.9961C26.3711 12.1875 27.4961 13.3125 27.4961 14.6875Z" :stroke="modeStrokeColor" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M28.7461 24.6875H27.4961" :stroke="modeStrokeColor" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M12.4961 24.6875H11.2461" :stroke="modeStrokeColor" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M27.5 22.5V18.75C27.5 17.375 26.375 16.25 25 16.25H15C13.625 16.25 12.5 17.375 12.5 18.75V22.5" :stroke="modeStrokeColor" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M27.5 23.125V24.6875H23.125C23.125 26.4125 21.725 27.8125 20 27.8125C18.275 27.8125 16.875 26.4125 16.875 24.6875H12.5V23.125C12.5 21.75 13.625 20.625 15 20.625H25C26.375 20.625 27.5 21.75 27.5 23.125Z" :stroke="modeStrokeColor" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </div>

                                <div class="basis-3/5 text-base leading-6 font-medium" :class="modeStore.selectedMode === 'dark' ? (currentView === 'archive' ? 'text-gray-300' : 'text-white') : 'text-gray-600'">{{ $t('feedback.archive')}}</div>

                                <div class="basis-1/5 flex items-center justify-center">
                                    <div
                                        v-show="archiveNotificationCount > 0"
                                        class="badge border-none rounded-lg"
                                        :class=" modeStore.selectedMode === 'dark' ? (currentView === 'archive' ? 'text-white bg-[#737579]' : 'text-white bg-custom-badge-dark') : 'text-gray-600 bg-custom-badge-light'"
                                    >
                                        {{ archiveNotificationCount }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button @click="changeView('writeMessage')" :data-theme='themeStore.selectedTheme' class="py-2 px-4 rounded-lg text-white">{{ $t('feedback.writeMessage')}}</button>
                    </div>

                    <!-- Feedback Content -->
                    <div :class="{'bg-white bg-opacity-20 text-black': modeStore.selectedMode === 'light', 'bg-[#1F29374D] bg-opacity-20': modeStore.selectedMode === 'dark'}" class="w-full lg:basis-4/5 p-1 lg:p-4 rounded-xl">
                        <!-- Filter Display -->
                        <!-- <div v-show="currentView !== 'writeMessage' && currentView !== 'archive'" v-if="isFilterDisplay"> -->
                            <!-- <div class="flex justify-between">
                                <h3 class="text-xl">Penapis</h3>
                                <div @click="resetFilter()" class="text-red-400 cursor-pointer">Tetapkan semula penapis</div>
                            </div> -->
                            
                            <!-- <div class="grid grid-cols-4 gap-10 py-4">
                                <div :class="modeStore.selectedMode === 'dark'? 'dark-mode' : 'light-mode'">
                                    <p>Jenis Fail</p>
                                    <select  v-model="filterByDocumentType" class="w-full py-2 px-4 my-2 rounded-md border-2 border-gray-700">
                                        <option value="all">Semua</option>
                                        <option value="video">Video</option>
                                        <option value="dokumen">{{ $t('general.document') }}</option>
                                    </select>
                                </div>
                                <div :class="modeStore.selectedMode === 'dark'? 'dark-mode' : 'light-mode'">
                                    <p>Dinaikkan Oleh</p>
                                    <select v-model="filterByUploadedBy" class="w-full py-2 px-4 my-2 rounded-md border-2 border-gray-700">
                                        <option value="admin">Admin</option>
                                        <option value="teacher">Cikgu</option>
                                    </select>
                                </div>
                                <div :class="modeStore.selectedMode === 'dark'? 'dark-mode' : 'light-mode'">
                                    <p>Subjek</p>
                                    <select v-model="filterBySubject" class="w-full py-2 px-4 my-2 rounded-md border-[1px] border-gray-700">
                                        <option value="all">Semua</option>
                                        <option value="science">Sains</option>
                                        <option value="mathematic">Matematik</option>
                                    </select>
                                </div>
                                <div>
                                    <p>Tarikh Dinaikkan</p>
                                    <VueDatePicker v-model="filterByDate" :enable-time-picker="false"  :dark="modeStore.selectedMode === 'dark'"
                                    class="w-[70%] py-2 rounded-md" />
                                </div>
                            </div> -->
                        <!-- </div> -->

                        <!-- Inbox / Sent Mail / Archived -->
                        <div class="flex flex-col gap-4" :class="{ 'theme-green': themeStore.selectedTheme === 'green', 'theme-orange': themeStore.selectedTheme === 'orange', 'theme-blue': themeStore.selectedTheme === 'blue', 'theme-purple': themeStore.selectedTheme === 'purple', 'theme-darkpink': themeStore.selectedTheme === 'darkpink'  }">
                            <!-- Action Row -->
                            <div v-show="currentView !== 'writeMessage' && currentView !== 'message-detail'" class="flex flex-row gap-4 p-2 items-center">
                                <input type="checkbox" class="my-auto rounded-md" v-model="selectAll"/>

                                <div id="chevron" class="dropdown dropdown-hover">
                                    <div tabindex="0" role="button" class="m-1">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.29289 7.29289C5.68342 6.90237 6.31658 6.90237 6.7071 7.29289L9.99999 10.5858L13.2929 7.29289C13.6834 6.90237 14.3166 6.90237 14.7071 7.29289C15.0976 7.68342 15.0976 8.31658 14.7071 8.70711L10.7071 12.7071C10.3166 13.0976 9.68341 13.0976 9.29289 12.7071L5.29289 8.70711C4.90237 8.31658 4.90237 7.68342 5.29289 7.29289Z" :fill="modeStrokeColor" />
                                        </svg>
                                    </div>
                                    <ul tabindex="0" class="dropdown-content z-[1] menu p-2 shadow-xl rounded-box w-52 border bg-white">
                                        <li>
                                            <div class="flex flex-row text-sm leading-5 font-medium">
                                                <button @click="retrieveAllMessages">{{ $t('feedback.all')}}</button>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="flex flex-row text-sm leading-5 font-medium">
                                                <button @click="retrieveReadMessages">{{ $t('feedback.read')}}</button>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="flex flex-row text-sm leading-5 font-medium">
                                                <button @click="retrieveUnreadMessages">{{ $t('feedback.unread')}}</button>
                                            </div>
                                        </li>
                                    </ul>
                                </div>

                                <div @click="refreshContent">
                                    <svg width="25" height="25" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M27.5 15C27.5 21.9 21.9 27.5 15 27.5C8.1 27.5 3.8875 20.55 3.8875 20.55M3.8875 20.55H9.5375M3.8875 20.55V26.8M2.5 15C2.5 8.1 8.05 2.5 15 2.5C23.3375 2.5 27.5 9.45 27.5 9.45M27.5 9.45V3.2M27.5 9.45H21.95" :stroke="modeStrokeColor" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </div>

                                <div id="triple-dots" class="dropdown dropdown-hover">
                                    <div tabindex="0" role="button" class="m-1">
                                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M15 6.25L15 6.2625M15 15L15 15.0125M15 23.75L15 23.7625M15 7.5C14.3096 7.5 13.75 6.94036 13.75 6.25C13.75 5.55964 14.3096 5 15 5C15.6904 5 16.25 5.55964 16.25 6.25C16.25 6.94036 15.6904 7.5 15 7.5ZM15 16.25C14.3096 16.25 13.75 15.6904 13.75 15C13.75 14.3096 14.3096 13.75 15 13.75C15.6904 13.75 16.25 14.3096 16.25 15C16.25 15.6904 15.6904 16.25 15 16.25ZM15 25C14.3096 25 13.75 24.4404 13.75 23.75C13.75 23.0596 14.3096 22.5 15 22.5C15.6904 22.5 16.25 23.0596 16.25 23.75C16.25 24.4404 15.6904 25 15 25Z" :stroke="modeStrokeColor" stroke-width="1.32733" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </div>
                                    <ul
                                        v-if="(currentView === 'inbox' && selectedInboxes.length > 0) || (currentView === 'sent-mail' && selectedSentMails.length > 0) || (currentView === 'archive' && selectedArchives.length > 0)"
                                        tabindex="0"
                                        class="dropdown-content z-[1] menu p-2 shadow-xl rounded-box w-52 border bg-white"
                                    >
                                        <li>
                                            <div class="flex flex-row text-sm leading-5 font-medium">
                                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M7.79232 17.4173H7.33398C3.66732 17.4173 1.83398 16.5007 1.83398 11.9173V7.33398C1.83398 3.66732 3.66732 1.83398 7.33398 1.83398H14.6673C18.334 1.83398 20.1673 3.66732 20.1673 7.33398V11.9173C20.1673 15.584 18.334 17.4173 14.6673 17.4173H14.209C13.9248 17.4173 13.6498 17.5548 13.4757 17.784L12.1007 19.6173C11.4957 20.424 10.5057 20.424 9.90065 19.6173L8.52565 17.784C8.37898 17.5823 8.03982 17.4173 7.79232 17.4173Z" stroke="black" stroke-width="1.61" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                                    <path d="M6.41602 7.33398H15.5827" stroke="black" stroke-width="1.61" stroke-linecap="round" stroke-linejoin="round"/>
                                                    <path d="M6.41602 11.918H11.916" stroke="black" stroke-width="1.61" stroke-linecap="round" stroke-linejoin="round"/>
                                                </svg>
                                                <button @click="markAllRead" class="text-left">{{ $t('feedback.markAllRead')}}</button>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="flex flex-row text-sm leading-5 font-medium">
                                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M7.79232 17.4173H7.33398C3.66732 17.4173 1.83398 16.5007 1.83398 11.9173V7.33398C1.83398 3.66732 3.66732 1.83398 7.33398 1.83398H14.6673C18.334 1.83398 20.1673 3.66732 20.1673 7.33398V11.9173C20.1673 15.584 18.334 17.4173 14.6673 17.4173H14.209C13.9248 17.4173 13.6498 17.5548 13.4757 17.784L12.1007 19.6173C11.4957 20.424 10.5057 20.424 9.90065 19.6173L8.52565 17.784C8.37898 17.5823 8.03982 17.4173 7.79232 17.4173Z" stroke="black" stroke-width="1.61" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                                    <path d="M6.41602 7.33398H15.5827" stroke="black" stroke-width="1.61" stroke-linecap="round" stroke-linejoin="round"/>
                                                    <path d="M6.41602 11.918H11.916" stroke="black" stroke-width="1.61" stroke-linecap="round" stroke-linejoin="round"/>
                                                </svg>
                                                <button @click="markAllUnread" class="text-left">{{ $t('feedback.markAllUnread')}}</button>
                                            </div>
                                        </li>
                                    </ul>

                                    <ul v-else tabindex="0" class="dropdown-content z-[1] menu p-2 shadow-xl rounded-box w-52 border bg-white">
                                        <div class="flex flex-row text-sm leading-5 font-medium py-3 px-6 text-gray-400 italic text-center">{{ $t('feedback.selectMsgForMoreAction')}}</div>
                                    </ul>
                                </div>
                            </div>

                            <!-- Email -->
                            <!-- Inbox -->
                            <div v-show="isLoadingInbox" class="flex items-center justify-center w-full gap-2 p-6">
                                <div>{{ $t('general.loading')}}</div>
                                <span class="loading loading-bars loading-sm"></span>
                            </div>

                            <div v-show="currentView === 'inbox' && !isLoadingInbox && isInboxEmpty">
                                <p class="flex items-center justify-center w-full py-28">{{ $t('feedback.noMessageFound')}}</p>
                            </div>

                            <div
                                v-show="currentView === 'inbox' && !isLoadingInbox && !isInboxEmpty"
                                v-for="inbox in inboxData"
                                :key="inbox.id" 
                                class="flex flex-col"
                            >
                                <div 
                                    class="flex flex-row items-center justify-center rounded-md border-b p-2"
                                    :class="[
                                        modeStore.selectedMode === 'dark' ? 'border-gray-700' : 'border-gray-200',
                                        inbox.is_unread ? 'font-bold': 'font-normal'
                                    ]"
                                >
                                    <div class="basis-[3%] flex items-center justify-center">
                                        <input type="checkbox" class="my-auto rounded-md" v-model="inbox.selected" />
                                    </div>
                                    <!-- <div class="basis-[3%] flex items-center justify-center">
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.15523 2.34172L10.3286 4.68839C10.4886 5.01505 10.9152 5.32839 11.2752 5.38839L13.4019 5.74172C14.7619 5.96839 15.0819 6.95505 14.1019 7.92839L12.4486 9.58172C12.1686 9.86172 12.0152 10.4017 12.1019 10.7884L12.5752 12.8351C12.9486 14.4551 12.0886 15.0817 10.6552 14.2351L8.66189 13.0551C8.30189 12.8417 7.70856 12.8417 7.34189 13.0551L5.34856 14.2351C3.92189 15.0817 3.05523 14.4484 3.42856 12.8351L3.90189 10.7884C3.98856 10.4017 3.83523 9.86172 3.55523 9.58172L1.90189 7.92839C0.928559 6.95505 1.24189 5.96839 2.60189 5.74172L4.72856 5.38839C5.08189 5.32839 5.50856 5.01505 5.66856 4.68839L6.84189 2.34172C7.48189 1.06839 8.52189 1.06839 9.15523 2.34172Z" stroke="#D1D5DB" stroke-width="1.61" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </div> -->
                                    <div :class="inbox.is_unread ? 'font-bold' : 'font-normal'" class="basis-[19%] flex items-center justify-start truncate">{{ inbox.from_user_id.name }}</div>
                                    <div @click="changeView('message-detail', inbox.id)" :class="inbox.is_unread ? 'font-bold' : 'font-normal'" class="basis-[65%] flex flex-row items-center justify-start truncate">
                                        <span class="max-w-[800px] truncate">(Message) {{ inbox.subject }}</span>
                                        <span v-if="inbox.content && inbox.content !== '<p></p>'">&nbsp;-&nbsp;</span>
                                        <span class="max-w-[400px] truncate" v-html="inbox.content"></span>
                                    </div>
                                    <div :class="inbox.is_unread ? 'font-bold' : 'font-normal'" class="basis-[10%] flex items-center justify-end">{{ inbox.created_date }}</div>
                                </div>
                            </div>

                            <!-- Dihantar -->
                            <div v-show="isLoadingSentMail" class="flex items-center justify-center w-full gap-2 p-6">
                                <div>{{ $t('general.loading')}}</div>
                                <span class="loading loading-bars loading-sm"></span>
                            </div>

                            <div v-show="currentView === 'sent-mail' && !isLoadingSentMail && isSentMailEmpty">
                                <p class="flex items-center justify-center w-full py-28">{{ $t('feedback.noSentMail')}}</p>
                            </div>

                            <div
                                v-show="currentView === 'sent-mail' && !isLoadingSentMail && !isSentMailEmpty"
                                v-for="sentMail in sentMailData"
                                :key="sentMail.id"
                                class="flex flex-col"
                            >
                                <div
                                    class="flex flex-row items-center justify-center border-b p-2"
                                    :class="[
                                        modeStore.selectedMode === 'dark' ? 'border-gray-700' : 'border-gray-200',
                                        sentMail.is_unread ? 'font-bold': 'font-normal'
                                    ]"
                                >
                                    <div class="basis-[3%] flex items-center justify-center">
                                        <input type="checkbox" class="my-auto rounded-md" v-model="sentMail.selected" />
                                    </div>
                                    <!-- <div class="basis-[3%] flex items-center justify-center">
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.15523 2.34172L10.3286 4.68839C10.4886 5.01505 10.9152 5.32839 11.2752 5.38839L13.4019 5.74172C14.7619 5.96839 15.0819 6.95505 14.1019 7.92839L12.4486 9.58172C12.1686 9.86172 12.0152 10.4017 12.1019 10.7884L12.5752 12.8351C12.9486 14.4551 12.0886 15.0817 10.6552 14.2351L8.66189 13.0551C8.30189 12.8417 7.70856 12.8417 7.34189 13.0551L5.34856 14.2351C3.92189 15.0817 3.05523 14.4484 3.42856 12.8351L3.90189 10.7884C3.98856 10.4017 3.83523 9.86172 3.55523 9.58172L1.90189 7.92839C0.928559 6.95505 1.24189 5.96839 2.60189 5.74172L4.72856 5.38839C5.08189 5.32839 5.50856 5.01505 5.66856 4.68839L6.84189 2.34172C7.48189 1.06839 8.52189 1.06839 9.15523 2.34172Z" stroke="#D1D5DB" stroke-width="1.61" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </div> -->
                                    <div class="basis-[19%] flex items-center justify-start truncate">To: {{ sentMail.to_user_id.name }}</div>
                                    <div @click="changeView('message-detail', sentMail.id)" class="basis-[65%] flex items-center justify-start truncate">
                                        <span class="max-w-[800px] truncate">(Message) {{ sentMail.subject }}</span>
                                        <span v-if="sentMail.content && sentMail.content !== '<p></p>'">&nbsp;-&nbsp;</span>
                                        <span class="max-w-[400px] truncate" v-html="sentMail.content"></span>
                                    </div>
                                    <div class="basis-[10%] flex items-center justify-end">{{ sentMail.created_date }}</div>
                                </div>
                            </div>
                            
                            <!-- Arkib -->
                            <div v-show="isLoadingArchive" class="flex items-center justify-center w-full gap-2 p-6">
                                <div>{{ $t('general.loading')}}</div>
                                <span class="loading loading-bars loading-sm"></span>
                            </div>

                            <div v-show="currentView === 'archive' && !isLoadingArchive && isArchiveEmpty">
                                <p class="flex items-center justify-center w-full py-28">{{ $t('feedback.noArchivedMail')}}</p>
                            </div>

                            <div
                                v-show="currentView === 'archive' && !isLoadingArchive && !isArchiveEmpty"
                                v-for="archive in archiveData"
                                :key="archive.id" 
                                class="flex flex-col"
                            >
                                <div
                                    class="flex flex-row items-center justify-center border-b p-2"
                                    :class="[
                                        modeStore.selectedMode === 'dark' ? 'border-gray-700' : 'border-gray-200',
                                        archive.is_unread ? 'font-bold': 'font-normal'
                                    ]"
                                >
                                    <div class="basis-[3%] flex items-center justify-center">
                                        <input type="checkbox" class="my-auto rounded-md" v-model="archive.selected" />
                                    </div>
                                    <div class="basis-[22%] flex items-center justify-start truncate">{{ archive.from_user_id.name }}</div>
                                    <div @click="changeView('message-detail', archive.id)" class="basis-[65%] flex items-center justify-start truncate">
                                        <span class="max-w-[800px] truncate">(Message) {{ archive.subject }}</span>
                                        <span v-if="archive.content && archive.content !== '<p></p>'">&nbsp;-&nbsp;</span>
                                        <span class="max-w-[400px] truncate" v-html="archive.content"></span>
                                    </div>
                                    <div class="basis-[10%] flex items-center justify-end">{{ archive.created_date }}</div>
                                </div>
                            </div>

                            <div v-show="isLoadingMessageDetail" class="flex items-center justify-center w-full gap-2 p-6">
                                <div>{{ $t('general.loading')}}</div>
                                <span class="loading loading-bars loading-sm"></span>
                            </div>

                            <div v-show="currentView === 'message-detail' && !isLoadingMessageDetail">
                                <!-- {{messageDetail}} -->
                                 <div v-if="messageDetail" class="pb-4 flex justify-between">
                                    <div>{{ messageDetail.subject }}</div>
                                    <div>{{ messageDetail.created_date }}</div>
                                 </div>
                                 <div>
                                    <div v-if="messageDetail" v-html="messageDetail.content"></div>
                                 </div>
                            </div>

                            <!-- Pagination -->
                            <div v-show="currentView !== 'writeMessage'" class="hidden lg:block">
                                <CustomPagination
                                    v-show="totalItems > 0"
                                    :showingFrom="showingFrom"
                                    :showingTo="showingTo"
                                    :totalItems="totalItems"
                                    :totalPages="totalPages"
                                    :currentPage="currentPage"
                                    :itemsPerPage="itemsPerPage"
                                    @change-page="changePage"
                                />
                            </div>
                        </div>

                        <!-- Write Message -->
                        <div
                            v-show="currentView === 'writeMessage'"
                            class="flex flex-col"
                        >
                            <h4 class="text-lg leading-6 font-medium mb-6">{{ $t('feedback.feedbackTitle')}}</h4>
                            <div class="flex flex-col gap-2 w-full">
                                <!-- Kepada -->
                                <div class="w-full lg:w-1/3">
                                    <div :class="modeStore.selectedMode === 'dark'? 'dark-mode' : 'light-mode'">
                                        <p class="text-base leading-6 font-medium">{{ $t('feedback.receiver')}}</p>
                                        <select 
                                            v-model="state.receiver"
                                            class="w-full py-2 px-4 my-2 rounded-md border cursor-pointer"
                                            :class="{'border-gray-300': modeStore.selectedMode === 'light', 'border-gray-700': modeStore.selectedMode === 'dark',  'border-red-500': v$.receiver.$errors.length }"
                                        >
                                            <option value="" disabled selected>{{ $t('feedback.select')}}</option>
                                            <option value="" disabled v-if="isLoadingKepadaOptions">{{ $t('general.loading')}}</option>
                                            <option 
                                                v-for="option in kepadaOptions"
                                                :key="option.id"
                                                :value="option.id"
                                                v-else
                                            >
                                                {{ option.name }}
                                            </option>
                                        </select>
                                        <div class="input-errors" v-for="error of v$.receiver.$errors" :key="error.$uid">
                                            <div class="error-msg">{{ error.$message }}</div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Tajuk -->
                                <div class="flex flex-col gap-2">
                                    <h5 class="text-base leading-6 font-medium">{{ $t('feedback.subject')}}</h5>
                                    <div class="flex justify-between border rounded-lg cursor-text" :class="{'border-gray-300': modeStore.selectedMode === 'light', 'border-gray-700': modeStore.selectedMode === 'dark', 'border-red-500': v$.subject.$errors.length}">
                                        <input 
                                            type="text"
                                            placeholder="Subjek"
                                            class="px-4 py-2 w-full bg-transparent focus:outline-none"
                                            v-model="state.subject"
                                        />
                                    </div>
                                    <div class="input-errors" v-for="error of v$.subject.$errors" :key="error.$uid">
                                            <div class="error-msg">{{ error.$message }}</div>
                                        </div>
                                </div>

                                <!-- Editor -->
                                <TiptapEditor v-model="editorContent"/>
                                <!-- <div class="input-errors" v-for="error of v$.editorContent.$errors" :key="error.$uid">
                                    <div class="error-msg">{{ error.$message }}</div>
                                </div> -->

                                <!-- Action Button -->
                                <div class="flex gap-2 justify-end my-auto pt-4">
                                    <button @click="SendMessage" :data-theme='themeStore.selectedTheme' class="py-2 px-4 rounded-lg text-white">{{ $t('feedback.send')}}</button>
                                    <button class="py-2 px-4 rounded-lg border border-gray-600">{{ $t('general.cancel')}}</button>
                                </div>
                            </div>
                        </div>

                        <div v-show="currentView === 'archive'"></div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<style scoped>
/* .truncate-content {
  @apply truncate;
  max-width: 100px; 
} */
</style>
import { defineStore } from 'pinia'

export const useAllSearchResultStore = defineStore('allSearchResult', {
    state: () => {
        return {
            searchQuery: null,
            showSearchResult: false, //the dropdown part
            showAllSearchResult: false,
            allSearchResult: [],
            isSearchResultLoading: false,
            isSearchResultDropdownLoading: false
        }
    },
    actions: {
        setSearchQuery(value) {
            this.searchQuery = value
        },
        setShowSearchResult(value) {
            this.showSearchResult = value
        },
        setShowAllSearchResult(value) {
            this.showAllSearchResult = value
        },
        setAllSearchResult(allSearchResult) {
            this.allSearchResult = allSearchResult
        },
        setIsSearchResultLoading(value) {
            this.isSearchResultLoading = value
        },
        setIsSearchResultDropdownLoading(value) {
            this.isSearchResultDropdownLoading = value
        }
    },
    persist: true,
})
import { defineStore } from 'pinia'

export const useModeStore = defineStore('selectedMode', {
    state: () => {
      return {
        selectedMode: 'dark' //light or dark
      }
    },
    actions: {
      setSelectedMode(mode) {
        this.selectedMode = mode
      }
    }
  })